import React, { useContext } from 'react';
import { css, cx } from 'linaria';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Image from '../components/Image';
import { theme } from '../../../Theme';
import { SmallText, SmallTextMedium, Heading5 } from '../../../ui/Typography';
import { LinkButton } from '../../../ui/Button';
import { Link } from 'react-router-dom'; // Imported Link for routing
import { handleLink } from '../../storyblok';

const SharedStyles = `
  width: 100%;
  background: ${theme.colors.white};
  text-align: left;
  display: block;

  a {
    text-decoration: none;
  }

  .content {
    padding: 1rem 0;
    ${theme.above.md} {
      padding: 1.5rem 0;
    }
  }

  .date {
    color: black;
    font-weight: 400;
  }

  .summary {
    text-align: left;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;

    ${theme.above.md} {
      -webkit-line-clamp: 2;
    }
  }

  h2 {
    ${Heading5};
    font-family: ${theme.fonts.secondary};
    text-transform: none;
    font-weight: 325;
    font-size: 1.5rem;
    letter-spacing: 0.002em;
  }
`;

const MinimalArticle = css`
  ${SharedStyles};

  h2 {
    margin: 0.2rem 0;
  }

  .more-button {
    margin-top: 0.5rem;
    display: block;
    width: auto;
    padding: 0;
  }

  .more-button a,
  .category-link {
    display: inline-block;
    text-transform: none;
    font-weight: 400;
    font-size: 0.875rem;
    color: black;
    text-decoration: none;
    margin: 0;
    transition: color 0.3s, text-decoration 0.3s ease;

    &:link,
    &:visited {
      color: black;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
      color: ${theme.colors.hoverPrimaryButton};
    }

    &:active {
      text-decoration: underline;
      color: ${theme.colors.hoverPrimaryButton};
    }
  }
`;

const FeaturedArticle = css`
  ${SharedStyles};
  position: relative;

  &:hover {
    .cta-button a {
      border-color: ${theme.colors.black};
    }
  }

  .image-wrapper {
    width: 100%;
    height: 235px;

    ${theme.above.md} {
      height: 285px;
    }
  }

  .content {
    padding: 2.875rem 1.25rem;
    ${theme.above.md} {
      padding: 4rem 1.25rem;
      margin: 0 auto;
    }
  }

  .summary {
    text-align: center;
    max-width: 385px;
    margin: 0 auto;
  }

  h2 {
    margin: 1.5rem 0;
  }

  .cta-button {
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    ${theme.above.md} {
      margin-top: 2rem;
    }
  }

  .cta-button a::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }
`;

const largeScreenArticle = css`
  ${theme.above.md} {
    width: 80%;
  }
`;

const largeScreenContent = css`
  ${theme.above.md} {
    width: 70%;
  }
`;

const testSummary =
  'Imagine leaving the hustle and bustle of the city behind and stepping into the serenity of nature. On Valön, with its rugged and stunning landscapes, we photographed our AW24 collection – created to provide warmth, protection, and style, whether you’re out on an adventure in nature or navigating urban environments. Valön’s vast views and rocky coastlines provide the perfect backdrop to showcase our garments and shoes, which are equally at home on city streets as they are on nature trails.';

const JournalCard = ({ minimalLayout, ...article }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const articlePublishedDate = new Date(
    article?.first_published_at
  ).toLocaleDateString(selectedChannel.language.culture);

  const ratio = article?.content?.ratio;
  const imageAspect =
    { '16:9': '16:9', '4:5': '4:5', '5:4': '5:4', '1:1': '1:1' }[ratio] ||
    '16:9';

  const articleClassName = ratio === '5:4' ? largeScreenArticle : '';
  const contentClassName = ratio === '5:4' ? largeScreenContent : '';

  const renderDateAndCategory = () => {
    const journalCategory =
      article?.content?.journal_categories?.[0]?.category?.[0]?.title;

    let categoryUrl = '';
    if (typeof window !== 'undefined' && journalCategory) {
      categoryUrl = `${
        window.location.pathname
      }?c=${journalCategory.toLowerCase().replace(/\s+/g, '-')}`;
    }

    if (journalCategory) {
      return (
        <span className={cx(SmallTextMedium, 'date')}>
          {articlePublishedDate}
          <span className="category-separator"> | </span>
          <Link
            to={
              categoryUrl ||
              `?c=${journalCategory.toLowerCase().replace(/\s+/g, '-')}`
            }
            className="category-link"
          >
            <span className="category">{journalCategory}</span>
          </Link>
        </span>
      );
    }
    return (
      <span className={cx(SmallTextMedium, 'date')}>
        {articlePublishedDate}
      </span>
    );
  };

  if (minimalLayout)
    return (
      <article className={cx(MinimalArticle, articleClassName)}>
        <Link to={handleLink(article?.full_slug)}>
          <div className="image-wrapper">
            <Image
              width={432}
              aspect={imageAspect}
              src={
                article?.content?.thumbnail_image?.filename ||
                article?.content?.hero_image?.filename
              }
              cover
            />
          </div>
          <div className={cx('content', contentClassName)}>
            {renderDateAndCategory()}
            <h2>{article?.content?.page_title}</h2>
            <p className={cx(SmallText, 'summary')}>
              {article?.content?.summary}
            </p>
            <div className="more-button">
              <a
                href={handleLink(article?.full_slug)}
                className="more-button-link"
              >
                {'Read more'}
              </a>
            </div>
          </div>
        </Link>
      </article>
    );

  return (
    <article className={FeaturedArticle}>
      <div className="image-wrapper">
        <Image
          width={1328}
          fillAvailableSpace
          src={article?.content?.hero_image?.filename}
        />
      </div>
      <div className="content">
        {articlePublishedDate}
        <h2>{article?.content?.page_title}</h2>
        <p className={cx(SmallText, 'summary')}>{article?.content?.summary}</p>
        <div className="cta-button">
          <LinkButton to={handleLink(article?.full_slug)} className="secondary">
            {article?.content?.cta_button}
          </LinkButton>
        </div>
      </div>
    </article>
  );
};

export default JournalCard;
